import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "./footer.css";
import ComingSoon from '../ComingSoon/ComingSoon';
import { useTranslation } from 'react-i18next';
import Boost from "./boost.png"
import Tap from "./tap.png"
import Invite from "./invite.png"
import Task from "./task.png"
import Mine from "./mine.png"
import inactiveBoost from "./inactiveBoost.png"
import inactiveTap from "./inactiveTap.png"
import inactiveInvite from "./inactiveInvite.png"
import inactiveTask from "./inactiveTask.png"
import inactiveMine from "./inactiveMine.png"

export default function Footer() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname)

    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className="menu">
            <div className={`menu-button ${isActive('/') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/'); }}>
                {isActive('/') ? <img src={Tap} /> : <img src={inactiveTap} />}
                <span className="button-text">Tap</span>
            </div>

            <div className={`menu-button ${isActive('/boosts') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/boosts'); }}>
                {isActive('/boosts') ? <img src={Boost} /> : <img src={inactiveBoost} />}
                <span className="button-text">Boost</span>
            </div>

            <div className={`menu-button ${isActive('/mine') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/mine'); }}>
                {isActive('/mine') ? <img src={Mine} /> : <img src={inactiveMine} />}
                <span className="button-text">Shop</span>
            </div>

            <div className={`menu-button ${isActive('/frens') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/frens'); }}>
                {isActive('/frens') ? <img src={Invite} /> : <img src={inactiveInvite} />}
                <span className="button-text">Invite</span>
            </div>

            <div className={`menu-button ${isActive('/earn/tasks') ? 'menu_selected' : ''}`}
                onClick={() => { navigate('/earn/tasks'); }}>
                {isActive('/earn/tasks') ? <img src={Task} /> : <img src={inactiveTask} />}
                <span className="button-text">Task</span>
            </div>
        </div>
    );
}
