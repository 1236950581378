import React, { useEffect, useState } from 'react';
import { useAppData } from '../../AppContext';
import coin from "../../coin.png";
import gift1 from './gift1.png';
import gift2 from './gift2.png';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import './friends.scss';
import { getInviteLink, getFriendsList, SendInvite, USER_ID, coin_name } from '../../http';
import { useTranslation } from 'react-i18next';
import rotate from "./rotate.png";
import Footer from '../Footer/Footer';
import copy from "./copy.png";
import invite from "./invite.png";
import avatar from "./avatar.png";
import {InviteFriend} from './InviteFriend';

const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(0) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(0) + 'K';
    }
    return num;
};

export const Frens = () => {
    const { t } = useTranslation();
    const [friendList, setFriendList] = useState([]);
    const [inviteLink, setInviteLink] = useState("");
    const [copied, setCopied] = useState(false);

    const fetchFriends = async () => {
        try {
            const data = await getFriendsList();
            if (data && Array.isArray(data.friends)) {
                setFriendList(data.friends);
            } else {
                setFriendList([]);
            }
        } catch (error) {
            console.error('Failed to fetch friends:', error);
            setFriendList([]);
        }
    };

    const getInviteLinkFunc = async () => {
        const data = await getInviteLink();
        if (data) {
            setInviteLink(data.link);
        }
    };

    useEffect(() => {
        fetchFriends();
        getInviteLinkFunc();
    }, []);

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(`https://t.me/${coin_name}?start=${USER_ID}`);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); 
        } catch (err) {
            console.error("Failed to copy!", err);
        }
    };

    const handleClick = () => {
        SendInvite();
        window.Telegram.WebApp.close();
    };

    return (
        <div className='outer_container_friends'>
            <div className='container_glow_friends'>
                <Back />
                <div className="tab-header">
                    <h2 className="tab-title">Invite Friends</h2>
                    <div>You and your friend will receive bonuses</div>
                </div>

                <div className="tab_block_friends">
                    <div className="tab-subblock task">
                        <img src={gift1} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title name">{t('friends.addFriend')}</div>
                            <div className='row'>
                                <div className='flex_center reword'>
                                    {t('friends.you', { count: 15000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" />
                                    <svg width="7" height="7" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="4" cy="4" r="4" fill="#FECC2F"/>
</svg>
                                    </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="tab-subblock task">
                        <img src={gift2} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title">{t('friends.addPremiumFriend')}</div>
                            <div className='row'>
                                <div className='flex_center reword'>
                                    {t('friends.you', { count: 75000 })}<img src={coin} className="coin_icon_task" alt="Coin-icon" />
                                    <svg width="7" height="7" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="4" cy="4" r="4" fill="#FECC2F"/>
</svg>
                                    </div>
                            
                            </div>
                        </div>
                    </div>
                </div>

                <div className='friends_reload_container'>List of your friends
                    <img src={rotate} onClick={() => fetchFriends()}></img>
                </div>
                <div className='friends'>
                    {friendList.length > 0 ? (
                        <div className='friends_column'>
                            {friendList.map(friend => (
                                <div className='friends_row'>
                                    <div className="friend">
                                        <img className='friend_img' src={friend.photo} alt={friend.name} />
                                        <div className="friend_info">
                                            <div>{friend.name}</div>
                                            <div className="friend_progress">
                                                <img src={coin} alt="Coin-icon" />
                                                <p className="friend_networth">{formatNumber(friend.total_coins_earned)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="bonus">
                                        <img src={coin} alt="Coin-icon" />
                                        <h2>+{formatNumber(friend.inviteBonus)}</h2>
                                    </div> */}
                                </div>
                            ))}
                            <div className="kostil" />
                        </div>
                    ) : (
                        <div>You have not invited anyone yet.</div>
                    )}
                </div>
                <div className='invite_container'>
                    <div className='invite_link' onClick={() => handleClick()}>
                        <div className='containet_text_ing_friends'>{copied ? "Copied" : `Invite a friend`}<img src={invite} alt="Invite icon" /></div>
                    </div>
                    <div className='copy' onClick={copyToClipboard}>
                        <img src={copy} alt="Copy link" />
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
};
